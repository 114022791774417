import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'PollySwap',
  description:
    'The most popular AMM on Polygon by user count! Earn CRACK through yield farming or win it in the Lottery, then stake it in Cages to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PollySwap), NFTs, and more, on a platform you can trust.',
  image: 'https://pollyswap.finance/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('PollySwap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('PollySwap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('PollySwap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('PollySwap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('PollySwap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('PollySwap')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('PollySwap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('PollySwap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('PollySwap')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('PollySwap')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('PollySwap')}`,
      }
    default:
      return null
  }
}
